import React from 'react';
import { usePagination } from './usePagination';
import { useVisibilitySettings } from './useVisibilitySettings';
import { useVisitedCalls } from './useVisitedCalls';
const initialState = {
    pagination: {},
    visitedCalls: {},
    visibilitySettings: {},
};
const SearchStateContext = React.createContext(initialState);
export const SearchStateProvider = ({ children }) => {
    const visitedCalls = useVisitedCalls();
    const pagination = usePagination();
    const visibilitySettings = useVisibilitySettings();
    const value = React.useMemo(() => ({
        pagination,
        visitedCalls,
        visibilitySettings,
    }), 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pagination.page, visitedCalls]);
    return React.createElement(SearchStateContext.Provider, { value: value }, children);
};
export const useSearchState = () => {
    const context = React.useContext(SearchStateContext);
    if (!context) {
        throw new Error('useSearchState must be used within a SearchStateProvider');
    }
    return context;
};
