import { isEqual, pick } from 'lodash';
import { trackerService } from 'browser/services/init';
import { authStore } from 'core/auth';
export const updateTimelineEntryDefinition = (timelineEntryDefinition, list) => list.map((i) => i.source.entityID === timelineEntryDefinition.source.entityID
    ? Object.assign(Object.assign({}, i), timelineEntryDefinition) : i);
export const mapFormValuesToInputKeys = (values) => {
    var _a;
    return ({
        title: values.category.value,
        text: values.name,
        style: {
            color: values.color,
        },
        keywords: ((_a = values.searchTerms) !== null && _a !== void 0 ? _a : []).map((searchTerm) => searchTerm.value),
        isEntryCreationDisabled: !values.isCreatable,
        isObvious: values.isObvious,
    });
};
export const getDisabledSCVTimelineEntryDefinitionShape = (definition) => ({
    source: pick(definition.source, ['type', 'entityType', 'entityID']),
});
export const updateClientSettingsOnDefinitionUpdate = async (definitionPrevData, definition, singleCaseDisabledDefinitions) => {
    if (definition.title === definitionPrevData.title)
        return;
    const oldDefFilterItem = getDisabledSCVTimelineEntryDefinitionShape(definitionPrevData);
    const updatedDefFilterItem = getDisabledSCVTimelineEntryDefinitionShape(definition);
    let shouldUpdate = false;
    const updatedDefinitionsList = singleCaseDisabledDefinitions === null || singleCaseDisabledDefinitions === void 0 ? void 0 : singleCaseDisabledDefinitions.map((disabledDefinition) => {
        if (isEqual(disabledDefinition, oldDefFilterItem)) {
            shouldUpdate = true;
            return updatedDefFilterItem;
        }
        return disabledDefinition;
    });
    if (shouldUpdate) {
        await authStore.updateOrganizationClientSettings({
            disabledSCVTimelineEntryFilterDefinitions: updatedDefinitionsList,
        });
        trackerService.track('[Annotation management] visibility changed on TED update', {
            disabledSCVTimelineEntryFilterDefinitions: updatedDefinitionsList,
        });
    }
};
export const updateClientSettingsOnDefinitionDelete = async (singleCaseDisabledFilters, definitionsToDelete) => {
    if (!definitionsToDelete)
        return;
    let shouldUpdate = false;
    const filteredDisabledDefinitions = singleCaseDisabledFilters === null || singleCaseDisabledFilters === void 0 ? void 0 : singleCaseDisabledFilters.filter((i) => {
        const foundInPersistedFilters = isEqual({ source: definitionsToDelete.source }, { source: i.source });
        if (foundInPersistedFilters) {
            shouldUpdate = true;
        }
        return !Boolean(foundInPersistedFilters);
    });
    if (shouldUpdate) {
        await authStore.updateOrganizationClientSettings({
            disabledSCVTimelineEntryFilterDefinitions: filteredDisabledDefinitions,
        });
        trackerService.track('[Annotation management] visibility changed on TED delete', {
            disabledSCVTimelineEntryFilterDefinitions: filteredDisabledDefinitions,
        });
    }
};
