import React from 'react';
import { Base, ChipV2 } from 'lib/cortiUI';
import { ScoreLabel } from '../../../components';
import { getReviewComplianceStatusData } from '../../../utils';
export const Score = ({ value, status }) => {
    const complianceStatusData = getReviewComplianceStatusData(status);
    return (React.createElement(Base, { height: "100%", width: "100%", display: "flex", gap: 3, alignItems: "center", justifyContent: "end" },
        React.createElement(ScoreLabel, { value: value, color: complianceStatusData.textColor }),
        React.createElement(ChipV2, Object.assign({ variant: "outlined" }, complianceStatusData))));
};
