import { sortBy } from 'lodash';
import React from 'react';
import { intl } from '@corti/i18n';
import { css, getScrollerCss } from '@corti/style';
import { useTheme } from '@corti/theme';
import { CallMetricsBreakdownItem, DetectionsCircularProgressBar } from 'lib/cortiAnalyticsUI';
import { Base, Card, CardContent, Tooltip, Typography } from 'lib/cortiUI';
import { useParametersContext } from '../../../activeView/context/ParametersContext';
export const CallMetricsWidget = ({ callMetrics }) => {
    const { goal } = useParametersContext();
    const theme = useTheme();
    const detectionsWithDescriptor = sortBy(callMetrics, (it) => it.source.text);
    const detectedQuestions = detectionsWithDescriptor.filter((it) => it.value !== 'not_detected').length;
    return (React.createElement(Base, { display: "flex", height: "100%", width: "100%", alignItems: "center" },
        React.createElement(Tooltip, { className: css({
                backgroundColor: 'transparent',
                padding: 0,
                maxWidth: 'unset',
            }), title: React.createElement(Card, { maxHeight: "50vh", overflow: "hidden", display: "flex", flexDirection: "column" },
                React.createElement(Typography, { p: 4, variant: "h6", width: "100%", color: "default" }, intl.t('performanceApp:questionBreakdown', 'Breakdown')),
                React.createElement(CardContent, { sx: { pt: 0, display: 'flex', flexDirection: 'column', gap: 3 }, className: css(getScrollerCss({ theme })) }, detectionsWithDescriptor.map((it) => (React.createElement(CallMetricsBreakdownItem, { title: it.source.text, value: it.value, key: it.source.key.entityID + it.source.key.type }))))) },
            React.createElement(Base, null,
                React.createElement(DetectionsCircularProgressBar, { target: goal.enabled ? goal.value / 100 : undefined, value: detectedQuestions / callMetrics.length, label: `${detectedQuestions} /  ${callMetrics.length}`, size: 24, strokeWidth: 4 })))));
};
