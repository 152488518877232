var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { searchInteractions, } from '@corti/mission-control-api';
import { coreStore } from 'browser/stores';
import { useSearchState } from '../../context';
const DEFAULT_FILTERS = {
    size: 20,
};
export const usePaginatedInteractions = (initialFilters) => {
    const filters = Object.assign(Object.assign({}, DEFAULT_FILTERS), initialFilters);
    const { t } = useTranslation('searchApp', { keyPrefix: 'grid' });
    const { pagination } = useSearchState();
    const { page, setPage, getCursorForPage, clearCursors, updateCursorForPage } = pagination;
    const [totalItems, setTotalItems] = React.useState(0);
    const [items, setItems] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    React.useEffect(() => {
        void loadData(page, getCursorForPage(page - 1));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    async function handlePageChange(newModel) {
        /**
         * When navigating back to the first page, we need to reset the cached cursors
         * since the first page fetches a fresh batch of recent interactions without using any cursor.
         */
        if (newModel.page === 0) {
            clearCursors();
        }
        await loadData(newModel.page, getCursorForPage(newModel.page - 1));
        setPage(newModel.page);
    }
    async function loadData(page, paginationObject) {
        if (isLoading)
            return;
        setIsLoading(true);
        try {
            const _a = await searchInteractions(Object.assign(Object.assign({}, filters), { page_field_value: paginationObject === null || paginationObject === void 0 ? void 0 : paginationObject.field_value, page_id_value: paginationObject === null || paginationObject === void 0 ? void 0 : paginationObject.id_value })), { results, total_count } = _a, res = __rest(_a, ["results", "total_count"]);
            setItems(results);
            if (totalItems === 0) {
                setTotalItems(total_count);
            }
            if (!getCursorForPage(page)) {
                updateCursorForPage(page, res.pagination);
            }
        }
        catch (error) {
            coreStore.notifications.showNotification({
                type: 'error',
                message: t('fetchInteractionsError', 'Failed to fetch interactions. Please try again later.'),
            });
        }
        finally {
            setIsLoading(false);
        }
    }
    return {
        items,
        isLoading,
        totalItems: totalItems !== null && totalItems !== void 0 ? totalItems : 0,
        pagination: {
            onPaginationModelChange: handlePageChange,
            pagination: true,
            paginationMode: 'server',
            paginationModel: { page, pageSize: filters.size },
            rowsPerPageOptions: [filters.size],
            pageSizeOptions: [filters.size],
        },
    };
};
