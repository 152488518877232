import { useMutation, useQuery } from '@apollo/client';
import React from 'react';
import { trackerService } from 'browser/services/init';
import { authStore } from 'core/auth';
import { useAuth } from 'core/auth/browser';
import { updateClientSettingsOnDefinitionDelete, updateClientSettingsOnDefinitionUpdate, updateTimelineEntryDefinition, } from '../utils';
import { MUTATIONS, QUERIES, } from './graphql';
const initialState = {
    definitions: [],
    definitionSources: [],
    isDataLoading: false,
    createDefinition: () => { },
    updateDefinition: () => { },
    deleteDefinition: () => { },
};
const DefinitionContext = React.createContext(initialState);
export const DefinitionContextProvider = ({ children }) => {
    const { organization } = useAuth();
    // Needed to temporary memorize definition ID for updating GQL cache and filters after
    // definition delete or update
    const tempDefinitionRef = React.useRef();
    const { data: definitionsData, loading: isDefinitionsLoading } = useQuery(QUERIES.GET_ALL_DEFINITIONS, {
        variables: {
            organizationID: organization.id,
        },
        nextFetchPolicy: 'network-only',
    });
    const { data: definitionSourcesData, loading: isDefinitionSourcesLoading } = useQuery(QUERIES.GET_SOURCES, {
        variables: {
            organizationID: organization.id,
        },
    });
    const [_createDefinition] = useMutation(MUTATIONS.CREATE, {
        onCompleted(data) {
            trackerService.track('[Annotation management] timeline entry definition created', data.createTimelineEntryDefinition);
        },
        update(cache, { data }) {
            if (data === null || data === void 0 ? void 0 : data.createTimelineEntryDefinition) {
                const { createTimelineEntryDefinition } = data;
                const fields = {
                    timelineEntryDefinitions: (existing = []) => {
                        return [createTimelineEntryDefinition, ...existing];
                    },
                };
                cache.modify({ fields });
            }
        },
    });
    const [_updateDefinition] = useMutation(MUTATIONS.UPDATE, {
        async onCompleted(data) {
            trackerService.track('[Annotation management] timeline entry definition updated', data.updateTimelineEntryDefinition);
        },
        update(cache, { data }) {
            if (data === null || data === void 0 ? void 0 : data.updateTimelineEntryDefinition) {
                const { updateTimelineEntryDefinition: definition } = data;
                const fields = {
                    timelineEntryDefinitions: (existing = []) => {
                        // Need to update ClientSettings before GQL cache
                        const editingDefinition = existing.find((tled) => tled.source.type ===
                            tempDefinitionRef.current.source.type &&
                            tled.source.entityID ===
                                tempDefinitionRef.current.source
                                    .entityID &&
                            tled.source.entityType ===
                                tempDefinitionRef.current.source
                                    .entityType);
                        if (editingDefinition) {
                            void updateClientSettingsOnDefinitionUpdate(editingDefinition, data.updateTimelineEntryDefinition, authStore.organization.clientSettings.disabledSCVTimelineEntryFilterDefinitions);
                        }
                        return updateTimelineEntryDefinition(definition, existing);
                    },
                };
                cache.modify({ fields });
            }
        },
    });
    const [_deleteDefinition] = useMutation(MUTATIONS.DELETE, {
        async onCompleted(data) {
            if (data.deleteTimelineEntryDefinition) {
                trackerService.track('[Annotation management] timeline entry definition deleted', tempDefinitionRef.current);
                if (tempDefinitionRef.current) {
                    void updateClientSettingsOnDefinitionDelete(authStore.organization.clientSettings.disabledSCVTimelineEntryFilterDefinitions, { source: tempDefinitionRef.current });
                }
            }
        },
        awaitRefetchQueries: true,
        refetchQueries: [
            {
                query: QUERIES.GET_ALL_DEFINITIONS,
                variables: {
                    organizationID: organization.id,
                },
            },
        ],
    });
    function createDefinition(definition) {
        void _createDefinition({ variables: { organizationID: organization.id, definition } });
    }
    async function updateDefinition(definition) {
        tempDefinitionRef.current = definition;
        await _updateDefinition({ variables: { organizationID: organization.id, definition } });
    }
    async function deleteDefinition(definition) {
        tempDefinitionRef.current = definition;
        await _deleteDefinition({ variables: { organizationID: organization.id, definition } });
    }
    const value = React.useMemo(() => {
        var _a, _b;
        return ({
            definitions: (_a = definitionsData === null || definitionsData === void 0 ? void 0 : definitionsData.timelineEntryDefinitions) !== null && _a !== void 0 ? _a : initialState.definitions,
            definitionSources: (_b = definitionSourcesData === null || definitionSourcesData === void 0 ? void 0 : definitionSourcesData.timelineEntryDefinitionSources) !== null && _b !== void 0 ? _b : initialState.definitionSources,
            isDataLoading: isDefinitionSourcesLoading || isDefinitionsLoading,
            createDefinition,
            updateDefinition,
            deleteDefinition,
        });
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [definitionsData, definitionSourcesData, isDefinitionSourcesLoading, isDefinitionsLoading]);
    return React.createElement(DefinitionContext.Provider, { value: value }, children);
};
export function useDefinitionContext() {
    return React.useContext(DefinitionContext);
}
