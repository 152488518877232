import { formatDateTime } from '@corti/date';
import { useTranslation } from '@corti/i18n';
import { Formatters } from '@corti/strings';
import { repositories } from 'core/repositories';
import { getGridNumericOperators } from 'lib/cortiUI';
import { DurationFilterInput } from '../Grid/GridFilterTimeInput';
export function useDataGridColumns() {
    const { t } = useTranslation('searchApp', { keyPrefix: 'grid' });
    return [
        {
            field: 'call.started_at',
            headerName: t('startTime', 'Started at'),
            type: 'dateTime',
            valueFormatter: (value) => formatDateTime(value),
            flex: 2,
        },
        {
            field: 'call.inbound_number',
            headerName: t('inboundPhone', 'Receiver Identifier'),
            flex: 2,
            type: 'string',
        },
        {
            field: 'call.direction',
            headerName: t('direction', 'Direction'),
            flex: 2,
            type: 'singleSelect',
            filterable: true,
            valueOptions: ['INBOUND', 'OUTBOUND', 'INTERNAL'],
        },
        {
            field: 'call.source_system',
            headerName: t('sourceSystem', 'Source System'),
            flex: 2,
            filterable: false,
        },
        Object.assign({ field: 'call.duration_millis', headerName: t('duration', 'Duration'), flex: 2, filterable: true, valueFormatter: (value) => Formatters.msToDuration(value) }, durationFilterRelatedProperties),
        {
            field: 'call.outbound_number',
            headerName: t('outboundPhone', 'Caller Identifier'),
            flex: 2,
            type: 'string',
        },
        {
            field: 'call.participants',
            headerName: t('participants', 'Participants'),
            flex: 2,
            type: 'singleSelect',
            filterable: true,
            valueOptions: repositories.users.users.map((u) => u.name),
        },
    ];
}
const AVAILABLE_DURATION_OPERATORS = ['=', '!=', '>', '>=', '<', '<=', 'isEmpty', 'isNotEmpty'];
const durationFilterRelatedProperties = {
    type: 'number',
    filterOperators: getGridNumericOperators()
        .filter((item) => AVAILABLE_DURATION_OPERATORS.includes(item.value))
        .map((item) => (Object.assign(Object.assign({}, item), { InputComponent: item.requiresFilterValue !== false ? DurationFilterInput : undefined }))),
};
