import React from 'react';
export const useVisitedCalls = () => {
    const [visitedCallsIDs, setVisitedCallsIDs] = React.useState([]);
    function addVisitedCall(id) {
        setVisitedCallsIDs((prev) => [...new Set([...prev, id])]);
    }
    function clearVisitedCalls() {
        setVisitedCallsIDs([]);
    }
    function isCallVisited(id) {
        return visitedCallsIDs.includes(id);
    }
    return {
        addVisitedCall,
        clearVisitedCalls,
        isCallVisited,
    };
};
