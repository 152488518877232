/**
 *
 * Utils for filter toolbar to filter out used columns
 */
export function filterColumns({ field, columns, currentFilters }) {
    const filteredFields = (currentFilters === null || currentFilters === void 0 ? void 0 : currentFilters.map((item) => item.field)) || [];
    return columns
        .filter((colDef) => colDef.filterable && (colDef.field === field || !filteredFields.includes(colDef.field)))
        .map((column) => column.field);
}
/**
 *
 * Utils for filter toolbar to preselect first available column option
 */
export function getColumnForNewFilter({ currentFilters, columns, }) {
    var _a, _b;
    const filteredFields = (currentFilters === null || currentFilters === void 0 ? void 0 : currentFilters.map(({ field }) => field)) || [];
    return ((_b = (_a = columns.find((colDef) => {
        var _a;
        return colDef.filterable &&
            !filteredFields.includes(colDef.field) &&
            ((_a = colDef.filterOperators) === null || _a === void 0 ? void 0 : _a.length);
    })) === null || _a === void 0 ? void 0 : _a.field) !== null && _b !== void 0 ? _b : null);
}
