import { repositories } from 'core/repositories';
/**
 *
 * Get grid rows from search results
 */
export function getRows(items) {
    return items.map((item) => {
        var _a, _b, _c, _d, _e;
        return ({
            id: ((_a = item.interaction) === null || _a === void 0 ? void 0 : _a.id) ? (_b = item.interaction) === null || _b === void 0 ? void 0 : _b.id : item.call.id,
            interactionID: (_c = item.interaction) === null || _c === void 0 ? void 0 : _c.id,
            'call.started_at': new Date(item.call.started_at),
            'call.inbound_number': item.call.inbound_number,
            'call.direction': item.call.direction,
            'call.source_system': item.call.source_system,
            'call.duration_millis': item.call.duration,
            'call.outbound_number': item.call.outbound_number,
            'call.participants': (_e = (_d = item.call.participants) === null || _d === void 0 ? void 0 : _d.map(({ user_id }) => { var _a, _b; return (_b = (_a = repositories.users.getUser(user_id)) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : ' '; })) !== null && _e !== void 0 ? _e : [],
        });
    });
}
