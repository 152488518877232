import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { Base, Button, ColorPickerTrigger, DataGrid, InputAdornment, ModalContent, TextField, } from 'lib/cortiUI';
import { CY_SELECTORS } from '../../utils';
export const TimelineEntryDefinitionSelect = ({ allTimelineEntryDefinitions, selectedTimelineEntryDefinitionSources, onApply, }) => {
    const { t } = useTranslation();
    const [filterInputValue, setFilterInputValue] = React.useState('');
    const [selectionModel, setSelectionModel] = React.useState([]);
    React.useEffect(() => {
        if (selectedTimelineEntryDefinitionSources.length) {
            const stringifiedDefSources = selectedTimelineEntryDefinitionSources.map(stringifySource);
            setSelectionModel(stringifiedDefSources);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    function stringifySource({ entityType, type, entityID = '' }) {
        return JSON.stringify({ entityID, entityType, type });
    }
    function getColumns() {
        return [
            {
                field: 'title',
                headerName: t('libFeedbackFormManager:timelineEntryDefinitionSelect.title', 'Category'),
                minWidth: 100,
                flex: 3,
                editable: false,
            },
            {
                field: 'text',
                headerName: t('libFeedbackFormManager:timelineEntryDefinitionSelect.text', 'Name'),
                minWidth: 100,
                flex: 3,
                editable: false,
            },
            {
                field: 'color',
                headerName: t('libFeedbackFormManager:timelineEntryDefinitionSelect.color', 'Color'),
                minWidth: 30,
                flex: 1,
                editable: false,
                renderCell: (p) => React.createElement(ColorPickerTrigger, { color: p.value, borderRadius: 4, border: "hidden" }),
            },
            {
                field: 'sourceEntityId',
                headerName: t('libFeedbackFormManager:timelineEntryDefinitionSelect.id', 'Entity Id'),
                minWidth: 80,
                flex: 2,
                editable: false,
            },
            {
                field: 'sourceEntityType',
                headerName: t('libFeedbackFormManager:timelineEntryDefinitionSelect.entityType', 'Entity Type'),
                minWidth: 80,
                flex: 2,
                editable: false,
            },
            {
                field: 'sourceType',
                headerName: t('libFeedbackFormManager:timelineEntryDefinitionSelect.type', 'Type'),
                minWidth: 80,
                flex: 2,
                editable: false,
            },
        ];
    }
    function getRows() {
        return allTimelineEntryDefinitions.filter(filterDefinition).map((it) => {
            var _a;
            return ({
                id: stringifySource(it.source),
                sourceEntityId: it.source.entityID,
                sourceEntityType: it.source.entityType,
                sourceType: it.source.type,
                title: it.title,
                text: it.text,
                color: (_a = it.style.color) !== null && _a !== void 0 ? _a : '#787878',
            });
        });
    }
    function matchString(input, target) {
        return target.toLowerCase().includes(input.toLowerCase());
    }
    function filterDefinition(definition) {
        return [
            definition.title,
            definition.text,
            definition.source.entityID,
            definition.source.entityType,
            definition.source.type,
        ].some((property) => matchString(filterInputValue, property !== null && property !== void 0 ? property : ''));
    }
    function handleApply() {
        const sources = selectionModel.map((it) => JSON.parse(it));
        onApply(sources);
    }
    const rows = getRows();
    const columns = getColumns();
    const toolbarComponentProps = {
        value: filterInputValue,
        onChange: (e) => setFilterInputValue(e.target.value),
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(ModalContent, null,
            React.createElement(Base, { display: "flex", flexDirection: "column", gap: 3 },
                React.createElement(Toolbar, Object.assign({}, toolbarComponentProps)),
                React.createElement(DataGrid, { disableColumnFilter: true, disableColumnMenu: true, rows: rows, columns: columns, checkboxSelection: true, onRowSelectionModelChange: setSelectionModel, rowSelectionModel: selectionModel, sx: {
                        border: 'hidden',
                        '& .MuiDataGrid-row': {
                            cursor: 'pointer',
                        },
                        '& .MuiDataGrid-cell:focus, .MuiDataGrid-cell:focus-within': {
                            outline: 'none',
                        },
                        '& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within': {
                            outline: 'none',
                        },
                    } }))),
        React.createElement(Base, { p: 6, display: "flex", flexDirection: "row", justifyContent: "flex-end" },
            React.createElement(Button, { color: "primary", onClick: handleApply, "data-cy": CY_SELECTORS.applyTimelineEntryDefsModalBtn }, t('apply', 'Apply')))));
};
function Toolbar(props) {
    const { t } = useTranslation();
    return (React.createElement(Base, { p: 4 },
        React.createElement(TextField, { sx: { maxWidth: 360 }, fullWidth: true, type: "search", placeholder: t('libFeedbackFormManager:filterInputPlaceholder', 'Quick filter'), InputProps: {
                startAdornment: (React.createElement(InputAdornment, { position: "start" },
                    React.createElement(SearchRoundedIcon, { fontSize: "small" }))),
            }, value: props.value, onChange: props.onChange })));
}
