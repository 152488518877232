import { useRef, useState } from 'react';
export const usePagination = () => {
    const [page, setPage] = useState(0);
    const paginationCursorMapRef = useRef({});
    function clearCursors() {
        paginationCursorMapRef.current = {};
    }
    function updateCursorForPage(page, cursor) {
        paginationCursorMapRef.current[page] = cursor;
    }
    function getCursorForPage(page) {
        return paginationCursorMapRef.current[page];
    }
    return {
        page,
        setPage,
        clearCursors,
        updateCursorForPage,
        getCursorForPage,
    };
};
