import React from 'react';
import { useTranslation } from '@corti/i18n';
import { getUserPreferences, saveUserPreferences, } from '@corti/mission-control-api';
import { trackerService } from 'browser/services/init';
import { coreStore } from 'browser/stores';
import { useDataGridColumns } from '../utils';
export const useVisibilitySettings = () => {
    const { t } = useTranslation('searchApp', { keyPrefix: 'visibilitySettings' });
    const ALL_COLUMNS = useDataGridColumns().map((column) => column.field);
    const [visibleColumns, setVisibleColumns] = React.useState([]);
    const [density, setDensity] = React.useState('standard');
    React.useEffect(() => {
        void fetchSettings();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    function handleDensityChange(newDensity) {
        setDensity(newDensity);
        void persistSettings({ density: newDensity });
        trackerService.track('[Search] data grid density change', {
            density: newDensity,
        });
    }
    function handleVisibleColumnsChange(newColumns) {
        setVisibleColumns(newColumns);
        void persistSettings({ columns: newColumns });
        trackerService.track('[Search] data grid columns visibility change', {
            visibleColumns: newColumns,
        });
    }
    async function fetchSettings() {
        try {
            const persistedSettings = await getUserPreferences();
            if (!Object.keys(persistedSettings).length) {
                setVisibleColumns(ALL_COLUMNS);
            }
            if (persistedSettings.columns.length) {
                setVisibleColumns(persistedSettings.columns);
            }
            if (persistedSettings.density)
                setDensity(persistedSettings.density);
        }
        catch (error) {
            console.error('[Search]: (useVisibilitySettings) Failed to fetch visibility settings', error);
        }
    }
    async function persistSettings(settings) {
        try {
            await saveUserPreferences({
                columns: settings.columns || (visibleColumns !== null && visibleColumns !== void 0 ? visibleColumns : []),
                density: settings.density || density,
            });
        }
        catch (error) {
            coreStore.notifications.showNotification({
                type: 'error',
                message: t('errorSavingSettings', 'Failed to persist user settings'),
            });
            console.error('[Search]: (useVisibilitySettings) Failed to persist user settings', error);
        }
    }
    return {
        visibleColumns,
        density,
        onVisibleColumnsChange: handleVisibleColumnsChange,
        onDensityChange: handleDensityChange,
    };
};
