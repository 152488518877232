import TimelapseRoundedIcon from '@mui/icons-material/TimelapseRounded';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { css, getScrollerCss } from '@corti/style';
import { useTheme } from '@corti/theme';
import { authStore } from 'core/auth';
import { config } from 'core/configuration/browser';
import { Base, Typography } from 'lib/cortiUI';
import { useInteraction } from '../../context';
import { useCallControls } from '../CallControlsContext';
import { Phrase } from './Phrase';
import { TranscriptsLoader } from './TranscriptsLoader';
import { api, convertData, initTranscriptsApiService } from './service';
export const Transcripts = () => {
    const theme = useTheme();
    const { t } = useTranslation('caseView', { keyPrefix: 'call.transcripts' });
    const { currentCallTime, changeCurrentCallTime } = useCallControls();
    const { interaction } = useInteraction();
    const [transcripts, setTranscripts] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);
    React.useEffect(() => {
        initTranscriptsApiService({
            host: config.getConfig().apiHost,
            authToken: authStore.authToken,
        });
    }, []);
    React.useEffect(() => {
        void fetchTranscripts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [interaction === null || interaction === void 0 ? void 0 : interaction.call_id]);
    async function fetchTranscripts() {
        setIsLoading(true);
        try {
            if (api && interaction) {
                const res = await api.getCallTranscript(interaction.call_id);
                setTranscripts(convertData(res));
            }
        }
        catch (e) {
            console.error(`Could not load Transcripts for Call`, e);
        }
        finally {
            setIsLoading(false);
        }
    }
    if (isLoading) {
        return React.createElement(TranscriptsLoader, null);
    }
    if (!transcripts.length) {
        return (React.createElement(Base, { display: "flex", alignItems: "center", justifyContent: "center", height: "100%", width: "100%" },
            React.createElement(Base, { display: "flex", alignItems: "center", justifyContent: "center", gap: 3 },
                React.createElement(TimelapseRoundedIcon, null),
                React.createElement(Typography, { variant: "body2" }, t('noTranscripts', 'Transcript will be available shortly...')))));
    }
    return (React.createElement(Base, { display: "flex", flexDirection: "column", gap: 4, height: "100%", overflow: "hidden" },
        React.createElement(Typography, { color: "default" }, t('transcripts', 'Transcripts')),
        React.createElement(Base, { display: "flex", flexDirection: "column", gap: 4, className: css({ overflow: 'overlay' }, getScrollerCss({ theme: theme })), pb: 4 }, transcripts.map((it, idx) => (React.createElement(Phrase, Object.assign({}, it, { key: `${it.time.toString()}_${idx}`, isActive: it.time <= currentCallTime, onClick: () => changeCurrentCallTime(it.time, true) })))))));
};
