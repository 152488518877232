import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Base, Chip, DataGrid, IconButton, Typography, } from 'lib/cortiUI';
export const FilesDataGrid = ({ filesList, onRemove }) => {
    var _a;
    const { t } = useTranslation('templatesManagement', { keyPrefix: 'filesGrid' });
    const { palette } = useTheme();
    const getStatusLabel = (status) => {
        switch (status) {
            case 'valid':
                return t(`status.valid`, 'Valid');
            case 'invalidSyntax':
                return t(`status.invalidSyntax`, 'Syntax error');
            case 'invalidType':
                return t(`status.invalidType`, 'Invalid type');
            case 'invalidShape':
                return t(`status.invalidShape`, 'Invalid shape');
            default:
                return t('status.noData', 'No data');
        }
    };
    const getColumns = () => [
        {
            field: 'name',
            headerName: t('headers.name', 'File name'),
            editable: false,
            flex: 2,
            renderCell: (props) => (React.createElement(Base, { height: "100%", display: "flex", alignItems: "center" },
                React.createElement(DescriptionRoundedIcon, { sx: { mr: 2 } }),
                React.createElement(Typography, { color: "default", variant: "body2" }, props.value))),
        },
        {
            field: 'status',
            headerName: t('headers.status', 'Status'),
            editable: false,
            flex: 2,
            renderCell: ({ value }) => (React.createElement(Chip, { className: css({
                    backgroundColor: `${value === 'valid' ? palette.success.main : palette.error.main} !important`,
                    color: '#fff',
                }), label: getStatusLabel(value) })),
        },
        {
            field: 'title',
            headerName: t('headers.title', 'Template title'),
            editable: false,
            flex: 3,
        },
        {
            field: 'count',
            headerName: t('headers.count', 'Questions count'),
            editable: false,
            flex: 1,
        },
        {
            field: 'action',
            headerName: '',
            editable: false,
            flex: 1,
            renderCell: () => (React.createElement(IconButton, { onClick: onRemove, icon: React.createElement(DeleteRoundedIcon, null), "data-cy": "delete-icon-btn" })),
        },
    ];
    const getRows = () => filesList.map((it) => {
        var _a, _b, _c, _d;
        return ({
            id: it.id,
            name: it.file.name,
            action: it.id,
            status: it.status,
            count: (_b = (_a = it.data) === null || _a === void 0 ? void 0 : _a.fields.length) !== null && _b !== void 0 ? _b : '-',
            title: (_d = (_c = it.data) === null || _c === void 0 ? void 0 : _c.title) !== null && _d !== void 0 ? _d : '-',
        });
    });
    const rows = (_a = getRows()) !== null && _a !== void 0 ? _a : [];
    const columns = getColumns();
    return (React.createElement(DataGrid, { rows: rows, columns: columns, disableColumnFilter: true, disableColumnMenu: true, disableRowSelectionOnClick: true, hideFooter: true, sx: {
            '& .MuiDataGrid-row': {
                cursor: 'pointer',
            },
            '& .MuiDataGrid-cell:focus, .MuiDataGrid-cell:focus-within, .MuiDataGrid-columnHeader:focus, .MuiDataGrid-columnHeader:focus-within': {
                outline: 'none',
            },
        } }));
};
